import {IMemeDevPrivateInfo} from "../models/MemeDevs";
import {MemeDevPrivateInfo} from "../views/MemeDevs";

export function toViewMemeDevPrivateInfo(info: IMemeDevPrivateInfo) : MemeDevPrivateInfo {
    return {
        birthDate: info.birth_date || "",
        deletedAt: info.deleted_at ? info.deleted_at : null,
        email: info.email,
        isVerified: info.is_verified,
        premExpiresAt: info.prem_expires_at && (Date.now() / 1000 < info.prem_expires_at) ? info.prem_expires_at : null,
    }
}