import {runInAction, makeAutoObservable, toJS} from "mobx";
import AuthService from "../services/AuthService";
import axios, {AxiosError} from "axios";
import {AuthResponse, RestorePasswordData} from "../models/Auth";
import {API_URL} from "../http";
import {jwtDecode, JwtPayload} from "jwt-decode";
import {IMemeDev} from "../models/MemeDevs";
import {LoginFormData, RegistrationFormData} from "../views/LoginRegister";
import {toModelLoginCreds, toModelRegistrationCreds} from "../converter/LoginRegister";
import MemeDevService from "../services/MemeDevService";
import {MemeDevPrivateInfo} from "../views/MemeDevs";
import {toViewMemeDevPrivateInfo} from "../converter/MemeDevs";

export const accessTokenName = "token"

export class AuthStore {
    isInited = false;
    isAuth = false;
    user: IMemeDev | null = null;
    privInfo: MemeDevPrivateInfo | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getUser = () => {
        return toJS(this.user);
    }

    fetchUser = async (id: number)=>  {
        const [user, privateInfo] = await Promise.all([
            MemeDevService.getMemeDevPublicInfo(id),
            MemeDevService.getMemeDevPrivateInfo()
        ]);

        runInAction(() => {
            this.user = user;
        })
        runInAction(() => {
            this.privInfo = toViewMemeDevPrivateInfo(privateInfo);
        })
    }

    setUser = (user: IMemeDev) => {
        this.user = user;
    }

    // setAuth(false) will reset user and privInfo
    setAuth = async (bool: boolean) => {
        if (bool) {
            const token = localStorage.getItem(accessTokenName);
            if (token) {
                const decoded = jwtDecode<JwtPayload>(token);
                if (decoded.sub) {
                    const id = parseInt(decoded.sub);
                    await this.fetchUser(id);
                }
            } else {
                throw new Error("Can't find auth token in local storage");
            }
        } else {
            this.user = null;
            this.privInfo = null;
        }
        runInAction(() => {
            this.isAuth = bool;
            // if (bool && this.user) {
            //     const idStr = localStorage.getItem("verified");
            //     if (idStr && this.user.id === parseInt(idStr)) {
            //         this.isVerified = true
            //     } else {
            //         MemeDevService.getMemeDevPrivateInfo().then((info) => {
            //             if (info.is_verified) {
            //                 this.setIsVerified(info.is_verified);
            //                 localStorage.setItem("verified", info.id.toString());
            //             }
            //             if (info.prem_expires_at && (Date.now()/1000 < info.prem_expires_at)) {
            //                 this.setExpiresAt(info.prem_expires_at);
            //             }
            //             if (info.deleted_at) {
            //                 this.setDeletedAt(info.deleted_at);
            //             }
            //         });
            //     }
            // }
        })
    }

    setEmail(email: string) {
        if (this.privInfo) {
            this.privInfo.email = email
        }
    }

    setBirthDate(date: string) {
        if (this.privInfo) {
            this.privInfo.birthDate = date
        }
    }

    setIsVerified(bool: boolean) {
        if (this.privInfo) {
            this.privInfo.isVerified = bool;
        }
    }

    setDeletedAt(unixTime: number | null) {
        if (this.privInfo) {
            this.privInfo.deletedAt = unixTime;
        }
    }

    setExpiresAt(unixTime: number | null) {
        if (this.privInfo) {
            this.privInfo.premExpiresAt = unixTime
        }
    }

    async login(data: LoginFormData) {
        const response = await AuthService.login(toModelLoginCreds(data));
        // console.log(response.data);
        localStorage.setItem(accessTokenName, response.data.access_token);
        await this.setAuth(true);
    }

    async registration(data: RegistrationFormData) {
        const response = await AuthService.registration(toModelRegistrationCreds(data));
        // console.log(response.data);
        localStorage.setItem(accessTokenName, response.data.access_token);
        await this.setAuth(true);
    }

    async verifyAccount(id: number, token: string) {
        const response = await AuthService.verifyAccount(id, token);
        localStorage.setItem("verified", `${id}`);
        console.log(response.data);
        this.setIsVerified(true);
    }

    async sendRestorePasswordEmail(email: string) {
        const response = await AuthService.sendRestorePasswordEmail({email: email});
    }

    async restorePassword(data: RestorePasswordData) {
        const response = await AuthService.restorePassword(data);
    }

    async logout(fromAll: boolean) {
        await AuthService.logout(fromAll);
        localStorage.removeItem(accessTokenName);
        await this.setAuth(false);
    }

    async checkAuth() {
        if (this.isAuth) {
            return;
        }
        try {
            const response =
                await axios.post<AuthResponse>(`${API_URL}/v1/auth/refresh-tokens`, {}, {withCredentials: true});
            localStorage.setItem(accessTokenName, response.data.access_token);
            await this.setAuth(true);
        } catch (e) {
            console.log(e);
            if (e instanceof AxiosError && e.response && e.response.status === 401) {
                localStorage.removeItem(accessTokenName);
                await this.setAuth(false);
            }
        } finally {
            runInAction(() => this.isInited = true)
        }
    }

    static checkJWT(token: string): boolean {
        try {
            const decoded = jwtDecode<JwtPayload>(token);
            if (!decoded.sub) {
                return false
            }
            if (!decoded.exp) {
                return false
            }
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            // console.log(currentTimeInSeconds);
            // console.log(decoded.exp);
            if (currentTimeInSeconds > decoded.exp) {
                return false
            }
        } catch (e) {
            console.log(e)
            return false
        }

        return true
    }
}