const translationsEN = {
    common: {
        and: "and",
        or: "or",
    },
    ui: {
        defErrMsg: "Something went wrong :(",
        notFoundErrMsg: "404. Page not found",
        loading: "Loading...",
        imageExportMsg: "Image loading is about to start",
        linkCopied: "Link copied to clipboard",
        pending: "Pending...",
        goToWebsite: "Go to website",
        localMeme: "Local meme",
        delete: "Delete",
        submit: "Confirm",
        cancel: "Cancel",
        tag: {
            showTags: "Show tags",
            hideTags: "Hide Tags",
            clickToBlock: "Click to add tag to blacklist",
            clickToUnblock: "Click to unblock a tag from the blacklist",
            tagBlocked: "The tag is blocked. Memes with this tag will be excluded from the selection",
            tagUnblocked: "Tag is unblocked"
        },
        memeDev: {
            block: "Block",
            unblock: "Unblock",
            memeDevBlocked: "The user is blocked. His content will be excluded from the selection",
            memeDevUnblocked: "User is unblocked",
        },
        betaInfo: "Dear User,\nthank you very much for participating in the beta test! If you find a bug or have ideas for improvement, write to us by email:",
        mobileMarketNoticeTitle: "Our application is available in stores:",
        cookieAttention: {
            title: "We use cookies",
            info: "By using our site you agree to the use of cookies. We use cookies for authorization. This helps us ensure that the website is easy to use.",
            understood: "I understand",
        },
        featureTemporarilyDisabled: "This feature is temporarily disabled, we are working on it",
        memeRatingTitleGlobal: "Estimated global rating value. Depends on the number of wins, losses and total number of votes",
        memeRatingTitleLocal: "Estimated local rating value. Depends on the number of wins, losses and total number of votes",
        memeWins: "Number of wins",
        memeLosts: "Number of defeats",
    },
    apiError: {
        _400: {
            invalidTemplateForm: "Invalid template form",
            invalidBirthDate: "Invalid birth date",
            imageMissing: "Image missing"
        },
        _401: {
            wrongLoginPwd: "Wrong login and/or password",
            wrongPwd: "Wrong password",
        },
        _403: {
            accountNotVerified: "Your account not verified",
            accessDenied: "Access denied",
        },
        _404: {
            notFound: "The requested resource is not found",
            memeNotFound: "Meme is not found",
            userNotFound: "User is not found",
            templateNotFound: "Template is not found",
            langNotSupported: "Language is not supported",
            regNotSupported: "Region does not exist or is not supported",
            activationLink: "Account activation link is invalid or has expired",
            pwdRestoreLink: "Password restore link is invalid or has expired",
            unknownReportCategory: "Unknown report category",
        },
        _409: {
            cantJudge: "This pair of memes is not available for judging",
            emailExists: "A user with given email already exists",
            nicknameExists: "A user with given nickname already exists",
            alreadyReported: "You have already reported this post",
        },
        _413: {
            imgTooLarge: "The image file size should not exceed 10 MB",
            stringBodyTooLarge: "The text data size should not exceed 10 KB",
        },
        _422: {
            imgFileType: "The image file format is not supported. Select jpg/jpeg or png",
            imgRatio: "Image is too stretched, the aspect ratio should not exceed 10 to 1",
            lang: "Selected language is not supported",
            reg: "Selected region is not supported",
            userCreation: {
                birthDate: "Your age must be between 12 and 100 years old",
                email: "Email must have valid format",
                firstName: "Firstname length must not exceed 100 characters",
                lastName: "Lastname length must not exceed 100 characters",
                nickname: "Nickname length must be between 3 and 20 characters, must contains only Latin letters and digits, can’t consist only of digits",
                password: "Password length must be between 6 and 20 characters",
            },
            memeOrTemplateCreation: {
                titles: {
                    memeTitles: "Without titles you can only create an untranslatable meme",
                    tmplTitles: "You cannot create a template without titles",
                    font_size: "Font size is too large or too small",
                    text: "The title must not be empty and must not be longer than 2000 characters",
                    angle: "Invalid title angle",
                    textColor: "Invalid title color",
                    xRatio: "The title protrudes beyond the vertical borders of the image",
                    yRatio: "The title protrudes beyond the horizontal borders of the image",
                },
                templateAddon: {
                    form: "Invalid meme or template form",
                    tags: "Tags must not be empty and must not have duplicates. The total number of characters must be no more than 100",
                },
            }
        },
        _429: {
            creationLimit: "Post limit exceeded. No more than 10 meme publications and no more than 10 templates per day are available",
            emailSendingLimit: "Email sending limit exceeded. No more than 1 letter every minute and no more than 10 letters every week are available",
        },
    },
    navbar: {
        hotMemes: "Hot memes",
        topMemeDevs: "Top developers",
        battleField: "Battle",
        builder: "Builder",

        localeSettingsTitle: "Meme language",
        additional: "Additional",
        support: "Contact support",
    },
    report: {
        popUpTitle: "Report",
        modalTitle: "Select report reason",
        spam: "Spam",
        violenceOrHostility: "Violence or hostility",
        adultContent: "Adult content",
        other: "Other",
        translation: "Wrong title translation",
        position: "Wrong title positioning",
        successSending: "Report sent",
    },
    authForm: {
        login: {
            title: "Login",
            email: "Email",
            password: "Password",
            forgotPwd: "Forgot your password?",
            restorePwd: "Restore password",
        },
        signUp: {
            title: "Sign Up",
            nickname: "Nickname (latin chars and digits)",
            firstname: "Firstname",
            lastname: "Lastname",
            birthDate: "Birth date",
            birthDateTooltip: "This will help us recommend you the best memes",
            enterYourAge: "Enter your age",
            yourAge: "Your age",
            memesLang: "Memes language",
            region: "Your region",
            email: "Email",
            password: "Password",
            submitPassword: "Submit password",
            summoner: "Inviter id or nickname",
            summonerTooltip: "If you were invited by a friend, do not forget to enter his nickname or Id",
            agreement: {
                agree: "I agree with the",
                privacy: "privacy policy",
                terms: "terms of use",
            },
            successSignUp: "An email with a link to verify your account was sent to the specified address" ,
            error: {
                pwdDontMatch: "Entered passwords do not match",
                age: "To sign up you must be at least 12 and not over 100 years old",
            }
        },
    },
    profile: {
        premium: {
            premiumAccount: "Premium account",
            daysLeft: "Days until premium status ends: ",
            monthsLeft: "Months until premium status ends: "
        },
        popUp: {
            myProfile: "My profile",
            settings: "Settings",
            logout: "Logout",
        },
        public: {
            wins: "Wins",
            defeats: "Defeats",
            judged: "Rated",
            position: "Position",
            memesCount: "Memes",
            rating: "Rating",
        },
        level: {
            l1: "Junior Meme-Developer",
            l2: "Middle Meme-Developer",
            l3: "Senior Meme-Developer",
            l4: "Guru Meme-Developer",
            l5: "Almost a Meme-God",
            l6: "Meme-God"
        },
        memes: {
            title: "Author memes",
            sort: {
                firstFresh: "First fresh",
                firstOld: "First old",
                ratingDown: "Rating down",
                ratingUp: "Rating up",
            },
            areYouSureDeleteMeme: "Are you sure you want to delete the meme?",
            successDeletion: "Meme deleted",
        },
        badges: {
            percentOfPlayersEarned: "%v of players have this achievement",
            nextLevel: "Next level",
        },
        settings: {
            title: "Profile settings",
            nicknameDesc: "Change Nickname",
            selectAvatar: "Select an avatar",
            avatarUpdated: "The avatar was successfully updated",
        },
        advancedSettings: {
            title: "Settings",
            locale: {
                title: "Locale",
                memesLangName: "Language",
                memesLangDesc: "Change memes language",
                regName: "Region",
                regDesc: "Change geographic region",
            },
            security: {
                title: "Security",
                emailName: "Email",
                emailDesc: "Your email",
                pwdName: "Password",
                changePwd: "Change password",
                fullLogoutName: "Logout",
                fullLogoutDesc: "Logout from all devices",
                enterCurrentPwd: "Enter the current password",
                enterNewPwd: "Enter a new password",
                pwdUpdateOrRestoreSuccess: "Your password has been changed",
                enterEmailToRestorePwd: "Enter the email to which your account is registered",
                sendRestoreLink: "Send restore link",
                emailToRestorePwdSent: "A restore link has been sent to the specified email",
            },
            blackList: {
                title: "Black list",
                clearBlackList: "Clear black list",
                clearBlockedTagsSubmitDesc: "Are you sure you want to clear the list of blocked tags?",
                clearBlockedMemeDevsSubmitDesc: "Are you sure you want to clear the list of blocked users?",
                blockedTagsName: "Blocked tags",
                noBlockedTags: "No blocked tags",
                blockedMemeDevsName: "Blocked meme developers",
                noBlockedMemeDevs: "No blocked meme developers",
            },
            additional: {
                title: "Additional",
                deleteAccount: "Delete account",
                restoreAccount: "Restore account",
                deleteAccountAttentionTitle: "Do you want to delete your account?",
                deleteAccountAttentionDesc: "You can cancel account deletion within 14 days. After this period, your account will be deleted permanently. Your personal data will be deleted. The memes you create will become anonymous.",
                acceptAccountDeletion: "Delete",
                accountDeletionMsg: "Your account will be deleted in %v days",
                accountDeletionCanceledMsg: "You have canceled account deletion",
            },
        },
        verification: {
            success: "Your account has been verified",
            bannerMsg: "Your account is not verified, check your email",
            bannerBtn: "Go to settings",
            settings: {
                title: "Account verification",
                sendEmail: "Click to receive confirmation email",
                emailIsSent: "An email has been sent to your email to confirm your account",
                nextEmailRemaining: "To resend the email, please wait. Remaining (seconds):",
            }
        },
    },
    topMemes: {
        title: "Hot memes selected for you",
        loginToSeeMore: "Login to see more memes",
    },
    duncanChest: {
        title: "Selected memes for this week",
    },
    battleField: {
        title: "Choose a winner",
        left: "Left",
        right: "Right",
        fullScreen: "Enter full screen mode",
        collapse: "Collapse",
        allBattlesJudged: "You have judged all battles currently available",
        signUpInfo: "Sign up and memes will not be repeated on different devices",
    },
    builder: {
        searchTmpl: {
            filterByForm: "Filer by form",
            searchByTags: "Search by tags",
            noSearchResults: "The search has not given any results",
            expandPreviewList: "Expand to full screen",
            collapsePreviewList: "Collapse",
        },
        meme: {
            originalLang: "Original language",
            create: "Create Meme",
            creationDesc: "New meme creation mode",
            editableTitlePlaceholder: "Enter text...",
            titlePlaceholder: "Some funny text",
            untranslatable: "Untranslatable meme",
            saveAsTmpl: "Save as template",
            upload: "Create the best meme!",
            successCreation: "Meme has been successfully uploaded!",
            validation: {
                emptyTitle: "Fill in or remove blank titles",
            }
        },
        tmpl: {
            create: "Create mew Template",
            creationDesc: "Create a new template that will be available to other creators",
            titlePlaceholder: "Funny text",
            upload: "Create awesome template!",
            successCreation: "Template has been successfully created!",
        },
        selectImage: "Select Image",
        selectForm: "Select form",
        pleaseSelectNewImage: "Please, select new image",
        appendTitle: "Append title",
        enterTags: "Enter tags separated by spaces:",
        tagsPlaceholder: "InformativeTag AnotherOneTag...",
    },
    support: {
        contactUsViaEmail: "You can contact us by writing an email to the following address: ",
        feelFreeToWriteUs: "If you find a bug or have ideas for improvement, please feel free to write to us. We will be very happy to receive your feedback!"
    }
} as const;

export default translationsEN;

export type TranslationKeys = typeof translationsEN;